html, body{
  height: screen;
  width: screen;
}

.sr-only {
  display: none;
}

.leaflet-popup-content {
  width: 90% !important;
}